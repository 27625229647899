// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-scenes-redirecting-index-tsx": () => import("/opt/build/repo/src/scenes/Redirecting/index.tsx" /* webpackChunkName: "component---src-scenes-redirecting-index-tsx" */),
  "component---src-scenes-contact-index-tsx": () => import("/opt/build/repo/src/scenes/Contact/index.tsx" /* webpackChunkName: "component---src-scenes-contact-index-tsx" */),
  "component---src-scenes-contact-individual-quantity-index-tsx": () => import("/opt/build/repo/src/scenes/ContactIndividualQuantity/index.tsx" /* webpackChunkName: "component---src-scenes-contact-individual-quantity-index-tsx" */),
  "component---src-scenes-contact-individual-index-tsx": () => import("/opt/build/repo/src/scenes/ContactIndividual/index.tsx" /* webpackChunkName: "component---src-scenes-contact-individual-index-tsx" */),
  "component---src-scenes-contact-embroiderer-index-tsx": () => import("/opt/build/repo/src/scenes/ContactEmbroiderer/index.tsx" /* webpackChunkName: "component---src-scenes-contact-embroiderer-index-tsx" */),
  "component---src-scenes-contact-business-index-tsx": () => import("/opt/build/repo/src/scenes/ContactBusiness/index.tsx" /* webpackChunkName: "component---src-scenes-contact-business-index-tsx" */),
  "component---src-scenes-old-contact-index-tsx": () => import("/opt/build/repo/src/scenes/OldContact/index.tsx" /* webpackChunkName: "component---src-scenes-old-contact-index-tsx" */),
  "component---src-scenes-page-template-index-tsx": () => import("/opt/build/repo/src/scenes/PageTemplate/index.tsx" /* webpackChunkName: "component---src-scenes-page-template-index-tsx" */),
  "component---src-scenes-product-details-index-tsx": () => import("/opt/build/repo/src/scenes/ProductDetails/index.tsx" /* webpackChunkName: "component---src-scenes-product-details-index-tsx" */),
  "component---src-scenes-products-index-tsx": () => import("/opt/build/repo/src/scenes/Products/index.tsx" /* webpackChunkName: "component---src-scenes-products-index-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

